@import 'styles/layers.scss';

@layer uikit {
  .line {
    background-color: var(--white);
    opacity: 0.1;
  }
  
  .horizontal {
    width: 100%;
    height: 1px;
  }
  
  .vertical {
    width: 1px;
  }
}