@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    border-radius: 4px;
    font-size: 12px;
    padding: 0 4px 0 6px;
    height: 24px;
    box-sizing: border-box;
    background-color: var(--black23);
  }

  .multiplier {
    margin-right: 6px;
    color: var(--white);
  }

  .text {
    margin-right: 6px;
  }
}